import React from "react"
import contactStyles from "./contact.module.scss"
import { motion } from "framer-motion"

const Contact = () => {
  return (
    <motion.div id="contact" className={contactStyles.contactContainer}  initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
   
      <div className={contactStyles.inner}>
        <ul>
          <li>
            <a target="_blank" href="mailto:pkim2320@gmail.com" rel="noreferrer">
          Email
            </a>
          </li>
        
          <li>
            <a target="_blank" href="https://www.github.com/ruuuh20" rel="noreferrer">
            GH
            </a>
          </li>
            <li>
            <a target="_blank" href="https://www.linkedin.com/in/pia-kim" rel="noreferrer">
              LI
            </a>
          </li>
          <li>
            <a target="_blank" href="https://twitter.com/aaaiip20" rel="noreferrer">
            TW
            </a>
          </li>
         
        </ul>
      </div>
    <div>&copy; PK {new Date().getFullYear()}</div>
    </motion.div>
  )
}

export default Contact
