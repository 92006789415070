import React from "react"
import { Link } from "gatsby"
import { useRef } from "react"
import heroStyles from "./hero.module.scss"
import { motion } from "framer-motion"
import logo from "../assets/gradient.png"
const Header = () => {

  return (
    <>
      <motion.header className={heroStyles.grid}  initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
        <div className={heroStyles.logo}>
          {/* <Link id={heroStyles.logoLink} to="/">
            P
          </Link> */}
          <Link className={heroStyles.logoRound} to="/"><img src={logo} alt="logo" /></Link>
        </div>

        <div className={heroStyles.logotype}>
          <span>Pia Kim</span>
        </div>
        <div className={`${heroStyles.tag}`}>
          <span> front-end developer </span>
        </div>
        <div></div>
        <div className="right-grid"></div>
        <div
          className={`${heroStyles.info} ${heroStyles.col2} ${heroStyles.taRight}`}
        >
          {/* <div className="right-grid"></div> */}
          <div className={heroStyles.rightGrid}>
            <Link className={heroStyles.aboutLink} to="/about">
              About
            </Link>
            {/* <a
              className={heroStyles.aboutLink}
              href="https://mikaip.hashnode.dev"
              style={{ paddingLeft: ".4rem" }}
            >
              Notes
            </a> */}
            {/* <motion.div
              className={heroStyles.star}
              animate={{
                rotate: 360,
                // borderRadius: [ "50% 50%", "2% 50%"],
                // x: 85
              }}
              initial={{
                x: -5,
              }}
              transition={{
                repeat: Infinity,
                duration: 3.5,
                ease: "linear",
              }}
              style={{
                height: "40px",
                width: "40px",
              }}
            > */}
              {/* <img
                src={icon}
                alt="icon"
                style={{
                  marginBottom: 0,
                  width: "30px",
                  height: "30px",
                  opacity: ".5",
                }}
              /> */}

              {/* <svg
                width="134"
                height="154"
                viewBox="0 0 134 154"
                fill="#0303b3"
                xmlns="http://www.w3.org/2000/svg"
                // style="transform: translate3d(0px, 0px, 0px) rotate(174.654deg);"
              >
                <path
                  d="M67 0L71.8895 68.5311L133.684 38.5L76.779 77L133.684 115.5L71.8895 85.4689L67 154L62.1105 85.4689L0.316048 115.5L57.221 77L0.316048 38.5L62.1105 68.5311L67 0Z"
                  class="secondary"
                ></path>
              </svg>
            </motion.div> */}
          </div>
        </div>
      </motion.header>
      {/* <div className={heroStyles.mobileMenu}>
        <div>Pia Kim </div>
      </div> */}
    </>
  )
}

export default Header
