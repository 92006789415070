import React from "react"
import Footer from "../components/footer"
import Header from "./header"
import "../styles/index.scss"
import layoutStyles from "./layout.module.scss"
import favicon from "../assets/favicon.ico"
import Helmet from "react-helmet"
import { motion,AnimatePresence, AnimateSharedLayout  } from 'framer-motion'
// import AnimatedCursor from '../components/cursor'

const Layout = props => {
 
//  const { x, y } = useMousePosition()

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header
      />
      <div className={layoutStyles.container}>
        <main className={layoutStyles.content}>{props.children}</main>
      </div>
      <Footer />
    </>
  )
}

export default Layout
