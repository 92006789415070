import React from "react"
import footerStyles from "./footer.module.scss"
import Contact from "./contact"
const Footer = () => {
  return (
    <footer className={footerStyles.footer}>
      <Contact />
     
    </footer>
  )
}

export default Footer
